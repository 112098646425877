
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsContext } from 'next';
import Page from 'components/Page';
import DeprecatedLayout from 'components/DeprecatedLayout';
import { getV1Props } from 'lib/page-props/getV1Props';
import { getV2Props } from 'lib/page-props/getV2Props';
import LayoutComponent from '_components/LayoutComponent';
import Breadcrumb from '_components/Breadcrumb';
import CookieBanner from '_components/CookieBanner';
import { fontFamilyMap } from './_app';
import useTranslation from 'next-translate/useTranslation';
import HeaderMeta from 'components/Layout/HeaderMeta';
import clsx from 'clsx';
import FaviconHead from 'components/FaviconHead';
import BackToTop from 'components/Layout/BackToTop';
interface HomePageProps {
    locale: 'th' | 'en' | 'vi' | 'id' | 'en-PH' | 'en-SG' | 'en-MY';
    tenant: string;
    page?: any;
    hrefLang?: any;
    layout?: any;
    settings?: any;
    breadcrumbs?: any[];
}
function HomePage(props: HomePageProps) {
    const { t } = useTranslation('layout');
    if (props.tenant !== 'asiadirect' &&
        (props.locale === 'th' || props.locale === 'en')) {
        return (<DeprecatedLayout {...props}>
        <FaviconHead tenant={props.tenant}/>
        <Page page={props.page} hrefLang={props.hrefLang}/>
      </DeprecatedLayout>);
    }
    const { page, locale, breadcrumbs, layout, settings } = props;
    return (<div style={{ fontFamily: fontFamilyMap[locale] }} className={clsx(props.tenant === 'asiadirect' && 'theme-adb')}>
      <LayoutComponent tenant={props.tenant} navigations={layout.navigation.map((nav: any) => ({
            text: nav.title,
            url: nav.linkUrl,
            subcategory: nav.subcategory.map((sub: any) => ({
                text: sub.title,
                url: sub.link,
            })),
        }))} contact={{
            phone: settings.phone,
            openingHours: settings.openingTimes,
            lineUrl: settings.lineUrl,
            messengerUrl: settings.messengerUrl,
        }} footer={{
            content: t('footer.content'),
            links: layout.footer.links,
        }} locale={layout.locale || ''}>
        {breadcrumbs && breadcrumbs.length > 0 && (<div className="p-2 max-w-7xl mx-auto md:p-4">
            <Breadcrumb items={breadcrumbs}/>
          </div>)}
        <Page page={page} tenant={props.tenant}/>
        <CookieBanner />
        <FaviconHead tenant={props.tenant}/>
        <BackToTop />
        {page.seo && <HeaderMeta {...page.seo}/>}
      </LayoutComponent>
    </div>);
}
async function getStaticProps({ locale }: GetStaticPropsContext) {
    locale = locale || 'th';
    let props;
    if (locale === 'th' || locale === 'en') {
        props = await getV1Props(locale, ['th', 'en']);
    }
    else {
        props = await getV2Props(locale);
    }
    return {
        props,
        revalidate: process.env.REVALIDATE_MS
            ? parseInt(process.env.REVALIDATE_MS)
            : 60,
    };
}
export default HomePage;

    async function __Next_Translate__getStaticProps__195faeb5e39__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195faeb5e39__ as getStaticProps }
  